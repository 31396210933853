import React from 'react';
import PropTypes from 'prop-types';

import '../styles/layout.css';

const Layout = ({ children }) => (
  <main style={{ margin: `0 auto`, paddingTop: 0 }}>{children}</main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
